<:Window on:popstate='fire("doPopState")'/>

<div class="helloWorld">

  <!-- TODO refactor into component -->
  <div class="messages">
    {{#each messages as message}}
      <div class="alert alert-{{message.type}}" out:fade>
        <div>{{message.title}}</div>
        <div>{{message.content}}</div>
      </div>
    {{/each}}
  </div>

  <h1>Svelte Demo App</h1>

  <nav class="navLinks">
    <a href="#{{routes.splash}}" on:click='fire("doRoute", event)'>Home</a>
    <a href="#{{routes.listUsers}}" on:click='fire("doRoute", event)'>List Users</a>
    <a href="#{{routes.testBroken}}" on:click='fire("doRoute", event)'>Test Broken</a>
  </nav>

  {{#if route === routes.splash}}

    <div class="page" in:fly='{x:2000}'>
      <Splash
        name='{{name}}' count='{{count}}'
        on:incrementCount='fire("incrementCount", event)'
        on:resetCount='fire("resetCount")'
      />
    </div>

  {{elseif route === routes.listUsers}}

    <div class="page" in:fly='{x:2000}'>
      <ListUsers
        isLoading='{{isLoading}}'
        items='{{items}}'
        sorting='{{sorting}}'
        filter='{{filter}}'
        on:filterData='fire("filterData", event)'
        on:requestData='requestData()'
        on:editItem='editItem(event)'
        on:deleteItem='fire("deleteItem", event)'
        on:updateSorting='fire("updateSorting", event)'
      />
    </div>

  {{elseif route === routes.editUser}}

    <div class="page" in:fly='{x:2000}'>
      <EditUser
        items='{{items}}'
        item='{{itemToEdit}}'
        on:requestData='requestData()'
        on:cancelEdit='cancelEdit(event)'
        on:saveUser='fire("saveUser", event)'
      />
    </div>

  {{else}}

    <div class="page" in:fly='{x:2000}'>
      <p>No route found for '{{route}}'</p>
    </div>

  {{/if}}

</div>

<script>
  import fly from 'svelte-transitions-fly';
  import fade from 'svelte-transitions-fade'
  import Config from '../modules/Config.js'
  import Routing from '../modules/Routing.js'
  import Splash from './Splash.html'
  import ListUsers from './ListUsers.html'
  import EditUser from './EditUser.html'

  export default {
    components: {
      Config,
      Splash,
      ListUsers,
      EditUser
    },
    transitions: { fly, fade },
    // oncreate () {
    //   // this.set({ routeParts: getRouteParts() }) // Set initial view. (default data removes need for this.)
    //   console.log('SvelteDemoApp oncreate')
    // },
    data () {
      return {
        routeParts: Routing.getRouteParts(),
        routes: Config.routes,
        isLoading: false,
        messages: [],
        count: 0,
        items: [],
        filter: '',
        sorting: {} // TODO Defining data structures at multiple levels...
      }
    },
    computed: {
      route: (routeParts) => (Routing.getMainRoute(routeParts)),
      itemToEdit: (items, routeParts) => {
        // routeParts should always be defined due to oncreate method already running?
        let foundItem = items.find(item => {
          return item.id.toString() === routeParts[1] // See editItem.
        })
        return foundItem || {}
      }
    },
    methods: {
        editItem: function (event) {
          // TODO Could check for populated items array at this point?
          // So, this means that Users component knows nothing about route generation, it just fired up the id.
          // TODO Could editItem be the event fired instead, then main.js translates that into doRoute?
          this.fire('doRoute', { href: Config.routes.editUser+'/'+event.id }) // FIXME weaksauce route url construction.
        },
        cancelEdit: function () {
          // TODO fire concelEdit, leave main.js to change data?
          this.fire('doRoute', { href: Config.routes.listUsers})
        },
        requestData: function () {
          // TODO isLoading... localise to Users component?
          // An example of internalizing data to the component (internal state), hiding from main.js data design.
          // What's best practice?
          this.set({ isLoading: true })
          this.fire('requestData')
        },
        setData: function (data) {
          data.isLoading = false
          data.originalItems = data.items.slice() // make a shallow copy for filtering etc.
          this.set(data)
        }
    }
  }
</script>
<style>
  .helloWorld {
    padding: var(--spacing-vertical-normal);
  }
  .helloWorld nav {
    margin-bottom: var(--spacing-vertical-normal);
  }
  .helloWorld .navLinks a {
    margin-right: var(--spacing-horizontal-small);
  }
  .messages {
    position: fixed;
    top: var(--spacing-vertical-small);
    width: 95%;
  }
</style>