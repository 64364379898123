<div class="users">
    <table class="table-striped">

        <thead>
            <th><a href="#" on:click='sortItems(event, { fieldName: "id" })'>Id {{getSortIcon('id', sorting, utfShapes)}}</a></th>
            <th><a href="#" on:click='sortItems(event, { fieldName: "firstName" })'>First Name {{getSortIcon('firstName', sorting, utfShapes)}}</a></th>
            <th><a href="#" on:click='sortItems(event, { fieldName: "lastName" })'>Last Name {{getSortIcon('lastName', sorting, utfShapes)}}</a></th>
            <th><a href="#" on:click='sortItems(event, { fieldName: "email" })'>Email {{getSortIcon('email', sorting, utfShapes)}}</a></th>
            <th><a href="#" on:click='sortItems(event, { fieldName: "iq" })'>IQ {{getSortIcon('iq', sorting, utfShapes)}}</a></th>
            <th>Actions</th>
        </thead>

        <tbody>
            {{#if items && items.length > 0}}
                {{#each processedItems as item}}
                    <tr>
                        <td><a href="#" on:click='doEditItem(event, item)'>{{item.id}}</a></td>
                        <td>{{item.firstName}}</td>
                        <td>{{item.lastName}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.iq}}</td>
                        <td>
                            <i class="noselect" on:click='fire("deleteItem", { id: item.id })'>{{unicodes.delete}}</i>
                        </td>
                    </tr>
                {{/each}}
            {{else}}
                <tr>
                    <td colspan="6">
                        {{#if isLoading}}
                            <span class="loader"></span>
                        {{else}} No rows present.
                            <button on:click='fire("requestData")'>Load</button>
                        {{/if}}
                    </td>
                </tr>
            {{/if}}
        </tbody>

    </table>
</div>

<script>
    import Config from '../modules/Config.js'

    export default {
        data() {
            return {
                unicodes: Config.unicodes,
                isLoading: false,
                items: [],
                sorting: {
                    active: true,
                    fieldName: 'id',
                    order: 'asc'
                },
                utfShapes: {
                    invis: '\u2063',
                    asc: '\u25B4',
                    desc: '\u25BE'
                }
            }
        },
        computed: {
            /**
             * TODO What's more efficient - computed property or a methods function?
             */
            processedItems: (items, sorting) => (
                items.sort((a, b) => {
                    let aV = a[sorting.fieldName]
                    let bV = b[sorting.fieldName]
                    if (aV === bV) { return 0; } // same value
                    // asc is default also.
                    let [lessThan, greaterThan] = (sorting.order === 'desc') ? [1, -1] : [-1, 1]
                    return (aV < bV) ? lessThan : greaterThan
                })
            )
        },
        helpers: {
            /**
             * Not a computed property - helper function with passed-in data context.
             */
            getSortIcon: function (fieldName, sorting, utfShapes) {
                if (!sorting.active) { return utfShapes.invis }
                if (sorting.fieldName !== fieldName) { return utfShapes.invis }
                // TODO per-field sorting values etc.
                return utfShapes[sorting.order]
            }
        },
        methods: {
            /**
             * When user changes sorting we only update the "sorting" meta-data.
             * The actual sorting happens as a computed property (processedItems).
             */
            sortItems: function(event, params) {
                event.preventDefault();
                this.fire('updateSorting', params)
            },
            doEditItem: function (event, item) {
                event.preventDefault()
                this.fire('editItem', { id: item.id })
            }
        }
    }
</script>

<style>
    .users {
        overflow: scroll;
    }
</style>